import Bugsnag from '@bugsnag/js';

export enum MHubErrorTypes {
  badRequest = 'MHUB:BAD_REQUEST',
  forbidden = 'MHUB:FORBIDDEN',
  notFound = 'MHUB:NOT_FOUND',
  preconditionFailed = 'MHUB:PRECONDITION_FAILED',
  unauthorized = 'MHUB:UNAUTHORIZED',
  internalServerError = 'MHUB:INTERNAL_SERVER_ERROR',
}

export interface MHubError {
  error_type: MHubErrorTypes;
  error_message: string;
  error_data?: any;
}

export function isMHubError(obj: any): obj is MHubError {
  return typeof obj.error_type === 'string'
    && typeof obj.error_message === 'string';
}

export function captureError(err: Error) {
  // error that occurs when connecting to Tinode - LMS users will only have
  // a chat id if they have a role on Chat (admin/assignee)
  if (err.message !== 'authentication required (401)'){
    Bugsnag.notify(err);
  }
}
