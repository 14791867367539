import React from 'react';
import { Auth } from 'aws-amplify';
import { isAWSError, AWSError } from '../../utils/aws';
import LoadingOverlay from '../../components/LoadingOverlay';
import { captureError } from '../../utils/errors';
import { getAdmins, getShowroomAPIURL, getSettings } from '../../utils/api';
import Bugsnag from '@bugsnag/js';
import { ChatAdmin } from '../../screens/Setting/Settings.types';
export interface AuthenticationData {
  username?: string;
  sub?: string;
  version?: string;
  phonePrefix?: string;
  phoneNumber?: string;
  name?: string;
  origin?: string;
  companyID: string;
  isAdmin: boolean;
  chatActivated: boolean;
  showroomAPIURL: string;
  projects?: Project[];
}

export interface SessionData {
  jwt: string;
  username: string;
}

interface AuthenticationState {
  readonly loading?: boolean;
  readonly authenticated?: boolean;
  readonly userData?: AuthenticationData;
}

interface AuthenticationActions {
  getAuthUser: () => AuthenticationData | null;
  getSessionData: () => Promise<SessionData | null>;
  authenticate: () => Promise<void>;
  signOut: () => Promise<void>;
}

interface Project {
  id: string;
  name: string;
  loanTrackerProjectID: string;
}

interface Branch {
  id: string;
  name: string;
  service_company_branch_id: string;
  viewAllProjects: boolean;
}
export interface IAuthenticationContext {
  state: AuthenticationState;
  actions: AuthenticationActions;
}

const initialState: AuthenticationState = {
  loading: true,
  authenticated: false,
  userData: undefined,
};

export const AuthenticationContext = React.createContext<IAuthenticationContext>({
  state: initialState,
  actions: {
    getAuthUser: () => null,
    getSessionData: async () => null,
    authenticate: async () => {},
    signOut: async () => {},
  },
});

export const AuthenticationContextConsumer = AuthenticationContext.Consumer;
export const AuthenticationContextProvider = AuthenticationContext.Provider;

interface IAuthenticationProviderProps {
  children: React.ReactNode;
}

interface IAuthenticationProviderState {
  readonly data: AuthenticationState;
}

export default class AuthenticationProvider extends React.Component<
  IAuthenticationProviderProps,
  IAuthenticationProviderState
> {
  constructor(props: IAuthenticationProviderProps) {
    super(props);
    this.state = {
      data: initialState,
    };
  }

  async componentDidMount() {
    await this.authenticate();
  }

  setStateAsync = (state: IAuthenticationProviderState): Promise<void> =>
    new Promise((res) => {
      this.setState(state, res);
    });

  getAuthUser = () => {
    const {
      data: { authenticated, userData },
    } = this.state;
    if (!authenticated) {
      return null;
    }
    if (userData) {
      return userData;
    }
    return null;
  };

  getSessionData = async (): Promise<SessionData | null> => {
    const {
      data: { authenticated },
    } = this.state;
    if (!authenticated) {
      return null;
    }
    const res = await Auth.currentSession();
    const accessToken = res.getAccessToken();
    const idToken = res.getIdToken();
    if (!idToken) {
      return null;
    }
    const username: string = idToken.payload['cognito:username'];
    const jwt = accessToken.getJwtToken();
    return {
      username,
      jwt,
    };
  };

  handleError = async (err: any) => {
    const { data } = this.state;
    if (isAWSError(err)) {
      const awsErr = err as AWSError;
      if (awsErr.code === 'NotAuthorizedException') {
        await this.setStateAsync({
          data: { ...data, loading: false },
        });
        return;
      }
    }
    captureError(err);
    await this.setStateAsync({
      data: { ...data, loading: false },
    });
  };

  fetchProjects = async (showroomAPIURL: string, userInfo: string, companyID: string) => {
    const arr: Array<Project> = [];

    const limit = 1000;
    const queries = [];
    if (limit > 0) {
      queries.push(`limit=${limit}`);
    }
    const search = '';
    if (search) {
      queries.push(`search=${search}`);
    }

    try {
      const projectAPI = `${showroomAPIURL}/projects/?limit=1000`;
      const res = await fetch(projectAPI, {
        credentials: 'include',
        mode: 'cors',
      });

      const d = await res.json();
      d.forEach((p: Project) => {
        const obj: Project = {
          id: p.id,
          name: p.name,
          loanTrackerProjectID: p.loanTrackerProjectID,
        };
        arr.push(obj);
      });

      return arr;
    } catch (err) {
        console.log("Error in fetchProjects: ", err)
    }
  };

  authenticate = async (): Promise<void> => {
    const { data } = this.state;
    await this.setStateAsync({ data: { ...data, loading: true } });
    let user;
    let projects: Project[] = [];

    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (err) {
      if (err !== 'not authenticated') {
        console.log('Error in authenticate: ', err);
      }
    }
    let authenticated = false;
    const newData = { ...data };
    if (user && user.attributes) {
      authenticated = true;
      let chatActivated = false;
      let isAdmin = false;
      const adms: ChatAdmin[] = await getAdmins(user.attributes['custom:company_id']) || [];
      const userID = user.attributes.sub || '';
      if (adms.length > 0) {
        adms.forEach((a: ChatAdmin) => {
          if (a.user_id === userID && a.view_all_projects === true) {
            isAdmin = true;
          }
        });
      }
      const settings = await getSettings(user.attributes['custom:company_id']);
      if (settings.length > 0) {
        if (settings[0].activated && settings[0].activated === true) {
          chatActivated = true;
        }
      }
      if (user.attributes) {
        let showroomAPIURL = '';
        const userInfo = `${user.attributes.name} (${user.attributes.email})`;
        const companyID = user.attributes['custom:company_id'];
        if (process.env.REACT_APP_MHUB_API_URL) {
          showroomAPIURL = await getShowroomAPIURL(userInfo, companyID);
          if (showroomAPIURL) {
            const projs = await this.fetchProjects(showroomAPIURL, userInfo, companyID);
            if (projs && projs.length > 0) {
              projs.forEach((p) => {
                projects.push({
                  id: p.id,
                  name: p.name,
                  loanTrackerProjectID: p.loanTrackerProjectID,
                });
              });
            }
          }
        }
        newData.userData = {
          sub: user.attributes.sub,
          version: user.attributes['custom:version'],
          phonePrefix: user.attributes['custom:phone_prefix'],
          phoneNumber: user.attributes.phone_number,
          name: user.attributes.name,
          origin: user.attributes['custom:origin'],
          companyID: companyID,
          isAdmin: isAdmin,
          showroomAPIURL: showroomAPIURL,
          projects: projects,
          chatActivated,
        };
      }
    }
    await this.setStateAsync({
      data: {
        ...newData,
        loading: false,
        authenticated,
      },
    });
  };

  signOut = async () => {
    try {
      await Auth.signOut();
    } catch (err) {
      console.log('Error in signout: ', err);
    }
    await this.setStateAsync({
      data: {
        ...initialState,
        loading: false,
      },
    });
  };

  renderContent() {
    const {
      data: { loading },
    } = this.state;
    const { children } = this.props;

    if (loading) {
      return <LoadingOverlay isLoading />;
    }

    return children;
  }

  render() {
    const { data } = this.state;

    const value: IAuthenticationContext = {
      actions: {
        authenticate: this.authenticate,
        getAuthUser: this.getAuthUser,
        getSessionData: this.getSessionData,
        signOut: this.signOut,
      },
      state: data,
    };

    return <AuthenticationContextProvider value={value}>{this.renderContent()}</AuthenticationContextProvider>;
  }
}
