import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import amplifyConfig from './configs/amplify';
import './index.css';
import App from './App';
import AuthenticationProvider from './contexts/Authentication';
import AdminProvider from './contexts/Admin';
import { customTheme } from './configs/theme';

Bugsnag.start({
  apiKey: 'f55a9bc5176a23c9cf9d211fe340e2cb',
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

Amplify.configure(amplifyConfig);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MuiThemeProvider theme={customTheme}>
        <AuthenticationProvider>
          <AdminProvider>
            <Router>
              <App />
            </Router>
          </AdminProvider>
        </AuthenticationProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
