/* eslint-disable import/prefer-default-export */

// btoa replacement. Stock btoa fails on on non-Latin1 strings.
export function b64EncodeUnicode(str: string) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(`0x${p1}`, 16))),
  );
}

export const formatSeachTerm = (searchTerm:string, companyID: string) => {
  let formattedSearch = searchTerm.split(' ').join('+').split(',').join('_');
  // must prepend company id as app is used by multiple companies
  formattedSearch = `${companyID}:${formattedSearch}`;
  // searching on tinode is case sensitive
  return formattedSearch.toLocaleLowerCase();
};
