import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingContentProps {
  isLoading?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    loadingOverlay: {
      zIndex: 10,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      backgroundColor: 'rgba(255,255,255,0.85)',
    },
  })
);

export default function LoadingOverlay({ isLoading }: LoadingContentProps) {
  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={classes.loadingOverlay}>
        <CircularProgress />
      </div>
    );
  }
  return null;
}
