// force update
import { API, Auth } from 'aws-amplify';
import Bugsnag from '@bugsnag/js';
import { captureError } from '../errors';
import { ChatAdmin, ChatConfigs, GetChatMembers } from '../../screens/Setting/Settings.types';

export async function post(api: string, path: string, options = {}) {
  const res = await API.post(api, path, options);
  return res;
}

export async function put(api: string, path: string, options = {}) {
  const res = await API.put(api, path, options);
  return res;
}

export async function get(api: string, path: string, options = {}) {
  const res = await API.get(api, path, options);
  return res;
}

export async function del(api: string, path: string, options = {}) {
  const res = await API.del(api, path, options);
  return res;
}

export async function getCustName(id: string) {
  try {
    const apiName = 'lead';
    const path = `/customers?id=${id}`;
    const myInit = {
      response: true,
    };
    const res = await get(apiName, path, myInit);
    if (res && res.status === 200 && res.data && res.data.name) {
      return res.data.name;
    }
  } catch (err) {
    console.log('getCustName error: ', err)
    return id;
  }
  return id;
}

export async function getAdmins(companyID: string) {
  if (companyID !== '') {
    try {
      const apiName = 'chat';
      const path = `/admins/read?company_id=${companyID}`;

      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
        response: true,
      };

      const res = await get(apiName, path, myInit);
      if (res) {
        if (res.data) {
          return res.data;
        }
      }
    } catch (err) {
      console.log('getAdmins error: ', err)
      return [];
    }
  }
  return [];
}

export async function getCompanyName(companyID: string) {
  if (companyID !== '') {
    try {
      const apiName = 'company';
      const path = `/companies/${companyID}`;

      const myInit = {
        response: true,
      };

      const res = await get(apiName, path, myInit);
      if (res) {
        if (res.data) {
          return res.data.name;
        }
      }
    } catch (err) {
      console.log('getCompanyName error: ', err)
      return 'Chat';
    }
  }
  return 'Chat';
}

export async function deleteAdmin(id: string) {
  try {
    const apiName = 'chat';
    const path = `/admins/delete?id=${id}`;

    const myInit = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      response: true,
    };

    await del(apiName, path, myInit);
  } catch (err) {
    console.log('deleteAdmin error: ', err)
    return id;
  }
  return id;
}

interface Settings {
  company_id: string;
  settings_type: string;
  action_by: string;
  activated?: boolean;
}

export async function addAdmin(user: ChatAdmin, createdBy: string) {
  try {
    const apiName = 'chat';
    const path = '/admins/create';
    const myInit = {
      body: user,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    await post(apiName, path, myInit);
  } catch (err) {
    console.log('addAdmin error: ', err)
    return user.id;
  }
  return user.id;
}

export async function editAdmin(newAdmin: ChatAdmin) {
  try {
    const apiName = 'chat';

    const path = '/admins/update';

    const myInit = {
      body: newAdmin,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    await put(apiName, path, myInit);
  } catch (err) {
    console.log('editAdmin error: ', err)
  }
}

export async function getSettings(companyID: string) {
  if (companyID !== '') {
    try {
      const apiName = 'chat';
      const path = `/settings/read?id=${companyID}`;
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
        response: true,
      };
      const res = await get(apiName, path, myInit);
      if (res) {
        if (res.data) {
          return res.data;
        }
      }
    } catch (err) {
      console.log('getSettings error: ', err)
      return [];
    }
  }
  return [];
}

export async function addSettings(settings: Settings) {
  try {
    let input = {};
    if (settings.settings_type === 'widget') {
      input = {
        created_by: settings.action_by,
        company_id: settings.company_id,
        activated: settings.activated || false,
        settings_type: settings.settings_type,
      };
    }
    const apiName = 'chat';
    const path = '/settings/create';
    const myInit = {
      body: input,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    await post(apiName, path, myInit);
  } catch (err) {
    console.log('addSettings error: ', err)
    return settings.company_id;
  }
  return settings.company_id;
}

//BH = business hours
export async function addBHSettings(settings: ChatConfigs, actionBy: string) {
  try {
    settings.bh_created_by = actionBy;
    settings.settings_type = 'business_hours';

    const apiName = 'chat';
    const path = '/settings/create';
    const myInit = {
      body: settings,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    await post(apiName, path, myInit);
  } catch (err) {
    console.log('addBHSettings error: ', err)
    return settings.company_id;
  }
  return settings.company_id;
}

export async function updateSettings(settings: Settings) {
  try {
    const input = {
      company_id: settings.company_id,
      settings_type: settings.settings_type,
      activated: settings.activated,
      updated_by: settings.action_by,
    };

    const apiName = 'chat';
    const path = '/settings/update';

    const myInit = {
      body: input,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    await put(apiName, path, myInit);
  } catch (err) {
    console.log('updateSettings error: ', err)
    return settings.company_id;
  }
  return settings.company_id;
}

export async function updateBHSettings(settings: ChatConfigs, actionBy: string) {
  try {
    settings.bh_updated_by = actionBy;
    settings.settings_type = 'business_hours';

    const apiName = 'chat';
    const path = '/settings/update';

    const myInit = {
      body: settings,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    await put(apiName, path, myInit);
  } catch (err) {
    console.log('updateBHSettings error: ', err)
    return settings.company_id;
  }
  return settings.company_id;
}

export async function updateBHStatus(companyID: string, status: boolean, actionBy: string) {
  try {
    const apiName = 'chat';
    const path = `/settings/updatebhstatus?id=${companyID}`;

    const input = {
      bh_status: status,
      bh_updated_by: actionBy,
    };

    const myInit = {
      body: input,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      response: true,
    };
    await put(apiName, path, myInit);
  } catch (err) {
    console.log('updateBHStatus error: ', err)
  }
}

export async function removeBHSettings(companyID: string) {
  try {
    const apiName = 'chat';
    const path = `/settings/removebh?id=${companyID}`;
    const myInit = {
      response: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    await put(apiName, path, myInit);
  } catch (err) {
    console.log('removeBHSettings error: ', err)
    return companyID;
  }
  return companyID;
}

const API_URL = process.env.REACT_APP_MHUB_API_URL;
export async function getShowroomAPIURL(cognitoUserID: string, companyID: string) {
  if (API_URL) {
    try {
      const res = await fetch(`${API_URL}/authenticated/`, {
        credentials: 'include',
        mode: 'cors',
      });
      if (!res.ok) {
        Bugsnag.notify(`Failed to GET ${API_URL}/authenticated/ for user ${cognitoUserID} from companyID ${companyID}`);
        return '';
      }
      const data = await res.json();
      if (!data.affiliate || !data.affiliate.company || !data.affiliate.company.apps) {
        Bugsnag.notify(`Company apps missing for user ${cognitoUserID} from companyID ${companyID}`);
        return '';
      }

      const { apps } = data.affiliate.company;
      if (apps) {
        const hasShowroom = apps.filter((app: any) => app.appID === 'showroom');
        if (hasShowroom) {
          const apiURL = hasShowroom[0].apiURL;
          return apiURL;
        }
        return '';
      }
      return '';
    } catch (err) {
      console.log('getShowroomAPIURL error: ', err)
      return '';
    }
  }
  return '';
}

export async function getShowroomBranchProjects(cognitoUserID: string, companyID: string, branchID: string) {
  if (API_URL) {
    const projectIDs: string[] = [];

    try {
      const res = await fetch(`${API_URL}/company_branches/${branchID}/`, {
        credentials: 'include',
        mode: 'cors',
      });
      if (!res.ok) {
        Bugsnag.notify(
          `Failed to GET ${API_URL}/company_branches/${branchID}/ for user ${cognitoUserID} from companyID ${companyID}`
        );
        return '';
      }
      const data = await res.json();

      const { branchProjects } = data;

      if (branchProjects && branchProjects.length > 0) {
        branchProjects.forEach((p: any) => {
          projectIDs.push(p.id);
        });
        return projectIDs;
      }
      return projectIDs;
    } catch (err) {
      console.log('getShowroomBranchProjects error: ', err)
      return projectIDs;
    }
  }
}

interface Opportunity {
  id?: string;
  name?: string;
  stage?: string;
  projectID?: string;
  unitNumber?: string;
}

export async function getOpptyInfo(id: string) {
  const apiName = 'lead';
  const path = `/customers/opportunity?id=${id}`;
  const myInit = {
    response: true,
  };
  let opp: Opportunity = {
    id: id,
  };
  let name = '';
  try {
    const res = await get(apiName, path, myInit);

    if (res) {
      if (res.data.project_name && res.data.project_name !== '') {
        if (res.data.unit_number && res.data.unit_number !== '') {
          name = `${res.data.project_name}, ${res.data.unit_number}`;
        } else {
          name = res.data.project_name;
        }
        opp.name = name;
      }

      if (res.data.stage !== '') {
        // remove underscore from stage 'follow_up'
        opp.stage = (res.data.stage.substr(0, 1).toUpperCase() + res.data.stage.substr(1)).replace(/_/g, ' ');
      }

      if (res.data.project_id !== '') {
        opp.projectID = res.data.project_id;
      }

      if (res.data.unit_number !== '') {
        opp.unitNumber = res.data.unit_number;
      }

      return opp;
    }
  } catch (err) {
    console.log('getOpptyInfo error: ', err)
    return opp;
  }
}

export async function getChatGroupMembers(input: GetChatMembers) {
  try {
    const apiName = 'chat';
    const path = '/chatgroup/members';
    const myInit = {
      body: input,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    const res = await post(apiName, path, myInit);
    return res;
  } catch (err) {
    console.log('getChatGroupMembers error: ', err)
  }
}
