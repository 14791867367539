export interface Config {
  readonly appName: string;
  readonly appVersion: string;

  readonly server: string;
  readonly serverKey: string;

  readonly cognitoAWSRegion: string;
  readonly cognitoAWSUserPoolID: string;
  readonly cognitoAWSIdentityPoolID: string;
  readonly cognitoAWSAppClientID: string;
  readonly cognitoAWSCookieDomain: string;
}

const config: Config = {
  appVersion: process.env.REACT_APP_VERSION || '0.0.0',
  appName: process.env.REACT_APP_NAME || 'mhub-chat-web',

  server: process.env.REACT_APP_SERVER || '',
  serverKey: process.env.REACT_APP_SERVER_KEY || '',

  cognitoAWSRegion: process.env.REACT_APP_COGNITO_AWS_REGION || '',
  cognitoAWSUserPoolID: process.env.REACT_APP_COGNITO_AWS_USER_POOL_ID || '',
  cognitoAWSIdentityPoolID: process.env.REACT_APP_COGNITO_AWS_IDENTITY_POOL_ID || '',
  cognitoAWSAppClientID: process.env.REACT_APP_COGNITO_AWS_APP_CLIENT_ID || '',
  cognitoAWSCookieDomain: process.env.REACT_APP_COGNITO_AWS_COOKIE_DOMAIN || '',
};

export default config;
