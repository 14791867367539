export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN_COMMUNITY = 'ADMIN_COMMUNITY';
export const ADMIN_BASIC = 'ADMIN_BASIC';
export const ADMIN = 'ADMIN';
export const HEADQUARTERS = 'HEADQUARTERS';
export const MORTGAGE_BANKER = 'MORTGAGE_BANKER';
export const TEAM_LEADER = 'TEAM_LEADER';
export const DEVELOPER_HEADQUARTERS = 'DEVELOPER_HEADQUARTERS';
export const DEVELOPER_BRANCH_LEADER = 'DEVELOPER_BRANCH_LEADER';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR_II = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR_II';
export const DEVELOPER_TEAM_LEADER = 'DEVELOPER_TEAM_LEADER';
export const DEVELOPER_EXECUTIVE = 'DEVELOPER_EXECUTIVE';
export const DEVELOPER_SITEPLAN = 'DEVELOPER_SITEPLAN';
export const AFFILIATE_I = 'AFFILIATE_I';
export const AFFILIATE_II = 'AFFILIATE_II';

export const VALID_GROUPS = [
  SUPER_ADMIN,
  ADMIN_COMMUNITY,
  ADMIN_BASIC,
  ADMIN,
  HEADQUARTERS,
  MORTGAGE_BANKER,
  TEAM_LEADER,
  DEVELOPER_HEADQUARTERS,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_TEAM_LEADER,
  DEVELOPER_EXECUTIVE,
  DEVELOPER_SITEPLAN,
  AFFILIATE_I,
  AFFILIATE_II,
];

export const ALLOWED_GROUPS = [
  SUPER_ADMIN,
  ADMIN_COMMUNITY,
  ADMIN_BASIC,
  ADMIN,
  DEVELOPER_HEADQUARTERS,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_TEAM_LEADER,
  DEVELOPER_EXECUTIVE,
  DEVELOPER_SITEPLAN,
  AFFILIATE_I,
  AFFILIATE_II,
];

export const UPPER_GROUPS = [
  ADMIN,
  SUPER_ADMIN,
  DEVELOPER_HEADQUARTERS,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
];

export function hasAllowedGroups(groups) {
  return Array.isArray(groups) && groups.length > 0 && groups.some((ag) => ALLOWED_GROUPS.some((g) => g === ag));
}

export function hasUpperGroups(groups) {
  return Array.isArray(groups) && groups.length > 0 && groups.some((ug) => UPPER_GROUPS.some((g) => g === ug));
}