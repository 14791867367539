import React from 'react';
import { AWSAppSyncClient } from 'aws-appsync';
import { gql } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { AuthenticationContext, IAuthenticationContext } from '../Authentication';
import { captureError } from '../../utils/errors';
import { getUserRole } from '../../data/roles';
import { ChatAdmin } from '../../screens/Setting/Settings.types';
import { getAdmins } from '../../utils/api';

interface CompanyBranches {
  id: string;
  is_external: string;
  name: string;
}

interface User {
  id: string;
  admin_name: string;
  company_id: string;
  company_branch_id: string;
  company_branch_name: string;
  email: string;
  groups: string[];
  phone: string;
  admin_role: string;
  updated_at?: string;
  user_status: string;
  disabled?: boolean;
}

interface DBUser {
  company_branch_id: string;
  name: string;
  company_id: string;
  email: string;
  groups: string[];
  id: string;
  phone_number: string;
  user_status: string;
}

interface UserState {
  readonly companyID: string;
  readonly ready?: boolean;
  readonly users?: User[];
  readonly companyBranches?: CompanyBranches[];
}

export interface IUserContext {
  state: UserState;
}

const initialState: UserState = {
  companyID: '',
  ready: false,
  users: [],
  companyBranches: [],
};

interface IUserProviderProps {
  filter?: string;
  children: React.ReactNode;
}

interface IUserProviderState {
  readonly data: UserState;
}

export const UserContext = React.createContext<IUserContext>({
  state: initialState,
});

export const UserContextProvider = UserContext.Provider;
export const UserContextConsumer = UserContext.Consumer;

export default class UserProvider extends React.Component<IUserProviderProps, IUserProviderState> {
  constructor(props: IUserProviderProps) {
    super(props);

    this.state = {
      data: initialState,
    };
  }

  async componentDidMount() {
    this.fetchUsers();
  }

  setStateAsync = (state: IUserProviderState): Promise<void> =>
    new Promise((res) => {
      this.setState(state, res);
    });

  lookupCompanyBranchName = (branchID: string) => {
    if (this.state.data.companyBranches) {
      const name = this.state.data.companyBranches.filter((arr) => arr.id === branchID)[0];
      if (name !== undefined) {
        return name.name;
      }
      return 'n/a';
    }
  };

  lookupCompanyBranchType = (branchID: string) => {
    if (this.state.data.companyBranches) {
      const branch = this.state.data.companyBranches.filter((arr) => arr.id === branchID)[0];
      if (branch && branch.is_external && branch.is_external !== '') {
        return branch.is_external;
      }
      return 'true';
    }
  };

  sortNameAsc = (a: User, b: User) => {
    if (a.admin_name < b.admin_name) {
      return -1;
    }
    if (a.admin_name > b.admin_name) {
      return 1;
    }
    return 0;
  };

  fetchCompanyBranches = () => {
    const { data } = this.state;
    const region = process.env.REACT_APP_COGNITO_AWS_REGION || '';
    const userDataSource = process.env.REACT_APP_APPSYNC_USER_ENDPOINT || '';
    if (region !== '' && userDataSource !== '') {
      const client = new AWSAppSyncClient({
        url: userDataSource,
        region,
        auth: {
          type: 'AMAZON_COGNITO_USER_POOLS',
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        disableOffline: true,
      });
      try {
        client
          .query({
            query: gql`
            query MyQuery{
              listCompanyBranches(company_id: "${data.companyID}") {
                company_branches {
                  id
                  is_external
                  name
                }
              }
            }
            `,
          })
          .then(async (result: any) => {
            const arr: Array<CompanyBranches> = [];
            result.data.listCompanyBranches.company_branches.forEach((u: any) => {
              const obj: CompanyBranches = {
                id: u.id,
                name: u.name,
                is_external: u.is_external,
              };
              arr.push(obj);
            });
            await this.setStateAsync({
              data: {
                ...data,
                companyBranches: arr,
              },
            });
            this.fetchUserList();
          });
      }
      catch (err) {
        console.log('Err in fetchCompanyBranches: ', err);
      }
    }
  };

  fetchUserList = () => {
    const { data } = this.state;
    let branchesLen = 0;
    if (data && data.companyBranches) {
      branchesLen = data.companyBranches.length;
    }
    if (branchesLen > 0) {
      const region = process.env.REACT_APP_COGNITO_AWS_REGION || '';
      const userDataSource = process.env.REACT_APP_APPSYNC_USER_ENDPOINT || '';
      if (region !== '' && userDataSource !== '') {
        const client = new AWSAppSyncClient({
          url: userDataSource,
          region,
          auth: {
            type: 'AMAZON_COGNITO_USER_POOLS',
            jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
          },
          disableOffline: true,
        });
        try {
          client
            .query({
              query: gql`
                query MyQuery{
                  listUsers(company_id: "${data.companyID}") {
                    count
                    users {
                      email
                      id
                      name
                      phone_number
                      company_branch_id
                      company_id
                      groups
                      user_status
                    }
                  }
                }
              `,
            })
            .then(async (result: any) => {
              const adms: ChatAdmin[] = await getAdmins(data.companyID);
              const arr: Array<User> = [];
              result.data.listUsers.users.forEach((u: DBUser) => {
                const isAgent = this.lookupCompanyBranchType(u.company_branch_id);
                if (isAgent === 'false') {
                  const obj: User = {
                    id: u.id,
                    admin_name: u.name,
                    email: u.email,
                    phone: u.phone_number,
                    company_id: u.company_id,
                    company_branch_id: u.company_branch_id,
                    groups: u.groups,
                    user_status: u.user_status,
                    company_branch_name: this.lookupCompanyBranchName(u.company_branch_id) || 'N/A',
                    admin_role: getUserRole(u.groups),
                  };
                  if (adms && adms.length > 0) {
                    const res = adms.filter((a) => a.user_id === u.id);
                    if (res.length > 0) {
                      obj.company_branch_name = '';
                      obj.admin_role = 'ALREADY ADDED AS ADMIN';
                      obj.disabled = true;
                    }
                  }
                  arr.push(obj);
                }
              });
              if (arr.length > 0) {
                arr.sort(this.sortNameAsc);
                await this.setStateAsync({
                  data: {
                    ...data,
                    users: arr,
                  },
                });
              }
            });
        } catch (err) {
          console.log('Err in fetchUserList: ', err);
        }
      }
    }
  };

  fetchUsers = async () => {
    const { state, actions }: IAuthenticationContext = this.context;
    const { data } = this.state;

    if (state.authenticated) {
      const authUser = actions.getAuthUser();
      if (authUser) {
        await this.setStateAsync({
          data: {
            ...data,
            companyID: authUser.companyID,
          },
        });
        this.fetchCompanyBranches();
      }
    }
  };

  handleError = (err: Error) => {
    captureError(err);
  };

  render() {
    const { children } = this.props;
    const { data } = this.state;
    const value: IUserContext = {
      state: data,
    };
    return <UserContextProvider value={value}>{children}</UserContextProvider>;
  }
}

UserProvider.contextType = AuthenticationContext;
