import React, { lazy, Suspense } from 'react';
import { useTheme } from '@material-ui/core/styles';

import { Switch, Route } from 'react-router-dom';
import UserProvider from '../contexts/User';
import LoadingOverlay from '../components/LoadingOverlay';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const Routes = (): React.ReactElement => {
  const theme = useTheme();

  const ChatsScreen = lazy(() => import('../screens/Chats/index'));
  const MobileHome = lazy(() => import('../screens/Home/Mobile'));
  const WebHome = lazy(() => import('../screens/Home/Web'));
  const Setting = lazy(() => import('../screens/Setting/Setting'));
  const NotFound = lazy(() => import('../screens/NotFound/NotFound'));
  const AdminList = lazy(() => import('../screens/Setting/components/AdminList'));
  const BusinessHours = lazy(() => import('../screens/Setting/components/BusinessHoursList'));
  const UnreadCount = lazy(() => import('../components/UnreadCount'));
  const UnreadCountMobile = lazy(() => import('../components/UnreadCountMobile'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Suspense fallback={<LoadingOverlay isLoading />}>
      <Switch>
        {isMobile && <Route exact path="/" component={MobileHome} />}
        {isMobile && <Route path="/chats/:filter/:topicID" component={ChatsScreen} />}
        {!isMobile && <Route exact path="/" component={WebHome} />}
        {!isMobile && <Route path="/chats/:filter/:topicID" component={WebHome} />}
        <Route path="/unreadcount" component={UnreadCount} />
        <Route path="/unreadcountmobile" component={UnreadCountMobile} />
        <Route exact path="/settings/businesshours" component={BusinessHours} />
        <Route exact path="/settings" component={Setting} />
        <Route exact path="/not_found" component={NotFound} />
        <Route
          exact
          path="/settings/admin"
          render={() => (
            <UserProvider>
              <AdminList />
            </UserProvider>
          )}
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
