
export function setObject<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getObject<T>(key: string): T | null {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
}

export function updateObject<T>(key: string, value: T) {
  const oldVal = getObject<T>(key);
  let v = value;
  if (value instanceof Object) {
    v = Object.assign(oldVal || {}, value);
  }
  setObject<T>(key, Object.assign(oldVal || {}, v));
}

export function removeItem(key: string) {
  localStorage.removeItem(key);
}
