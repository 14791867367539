import config from './config';

interface AmplifyConfig {
  Auth: {
    identityPoolId?: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    authenticationFlowType?: string;
    mandatorySignIn?:boolean;
    cookieStorage: {
      domain?: string;
      secure: boolean;
    };
  };
  API: {};
}

const amplifyConfig: AmplifyConfig = {
  Auth: {
    identityPoolId: config.cognitoAWSIdentityPoolID,
    region: config.cognitoAWSRegion,
    userPoolId: config.cognitoAWSUserPoolID,
    userPoolWebClientId: config.cognitoAWSAppClientID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
    cookieStorage: {
      domain: config.cognitoAWSCookieDomain,
      secure: true,
    },
  },
  API: {
    endpoints: [
      {
        name: 'lead',
        endpoint: process.env.REACT_APP_LEAD_API_URL,
        region: process.env.REACT_APP_COGNITO_AWS_REGION,
      },
      {
        name: 'chat',
        endpoint: process.env.REACT_APP_CHAT_API_URL,
        region: process.env.REACT_APP_COGNITO_AWS_REGION,
      },
      {
        name: 'company',
        endpoint: process.env.REACT_APP_COMPANY_API_URL,
        region: process.env.REACT_APP_COGNITO_AWS_REGION,
      },
    ],
  },
};

export default amplifyConfig;