import React from 'react';
import Tinode, { Topic } from 'tinode-sdk';
import { AuthenticationContext, IAuthenticationContext } from '../Authentication';
import config from '../../configs/config';
import { b64EncodeUnicode } from '../../utils/strings';
import * as storage from '../../utils/storage';
import { captureError } from '../../utils/errors';
import { ChatSession } from '../../screens/Setting/Settings.types';

export interface TopicInfo {
  assignee?: string;
  custNames?: string;
  oppID?: string;
  oppStatus?: string;
  projectID?: string;
  topicName: string;
}

interface ChatState {
  readonly myUserID?: string;
  readonly ready?: boolean;
  readonly connected?: boolean;
  readonly list?: ChatSession[];
  readonly query?: string;
}

interface ChatActions {
  getTinode(): Tinode | null;
  getTopic(topicID: string): Topic | null;
  search(topicID: string): Topic | null;
}

export interface IChatContext {
  state: ChatState;
  actions: ChatActions;
}

const initialState: ChatState = {
  myUserID: undefined,
  ready: false,
  list: [],
};

export const ChatContext = React.createContext<IChatContext>({
  state: initialState,
  actions: {
    getTinode: () => null,
    getTopic: () => null,
    search: () => null,
  },
});

export const ChatContextProvider = ChatContext.Provider;
export const ChatContextConsumer = ChatContext.Consumer;

interface IChatProviderProps {
  filter?: string;
  children: React.ReactNode;
}

interface IChatProviderState {
  readonly data: ChatState;
}

export default class ChatProvider extends React.Component<IChatProviderProps, IChatProviderState> {
  tinode: Tinode;

  constructor(props: IChatProviderProps) {
    super(props);
    this.tinode = ChatProvider.setupTinode();
    this.tinode.enableLogging(false, true);
    this.state = {
      data: initialState,
    };
  }

  async componentDidMount() {
    this.setTinodeHooks();

    try {
      const tokenStore = storage.getObject<{ expires: string; token: string }>('auth-token');
      if (tokenStore) {
        const expires = new Date(tokenStore.expires);
        this.tinode.setAuthToken({
          expires,
          token: tokenStore.token,
        });
      }
    } catch (err) {
      this.handleError(err);
    }

    await this.tinode.connect();
  }

  static setupTinode() {
    return new Tinode(`${config.appName}/${config.appVersion}`, config.server, config.serverKey, null, true);
  }

  setTinodeHooks() {
    this.tinode.onConnect = this.handleConnected;
  }

  setStateAsync = (state: IChatProviderState): Promise<void> =>
    new Promise((res) => {
      this.setState(state, res);
    });

  getTinode = () => this.tinode;

  getTopic = (topicID: string) => {
    const topic = this.tinode.getTopic(topicID);
    if (!topic) {
      return null;
    }
    return topic;
  };

  search = (topicID: string) => {
    const topic = this.tinode.getTopic(topicID);
    if (!topic) {
      return null;
    }
    return topic;
  };

  handleConnected = async () => {
    const { state, actions }: IAuthenticationContext = this.context;
    if (state.authenticated) {
      const session = await actions.getSessionData();
      if (session) {
        try {
          await this.authenticateChat(session.username, session.jwt);
        } catch (err) {
          this.handleError(err);
        }
      }
    }
  };

  handleAuthSuccessful = async () => {
    const { data } = this.state;

    storage.setObject('auth-token', this.tinode.getAuthToken());

    const me = this.tinode.getMeTopic();

    me.onMetaDesc = (desc) => {};
    me.onContactUpdate = this.handleContactUpdate;
    me.onSubsUpdated = this.handleResetChatList;

    try {
      await me.subscribe(me.startMetaQuery().withLaterSub().withDesc().withTags().withCred().build());
    } catch (err) {
      captureError(err);
      this.tinode.disconnect();
      storage.removeItem('auth-token');
    }

    await this.setStateAsync({
      data: {
        ...data,
        myUserID: this.tinode.getCurrentUserID(),
        connected: true,
      },
    });
  };

  handleError = (err: Error) => {
    captureError(err);
  };

  handleContactUpdate = (what: string, cont: any) => {
    const topic = this.tinode.getTopic(cont.topic);
    const archived = topic && topic.isArchived();
    switch (what) {
      case 'recv':
      case 'acs':
      case 'del':
      case 'upd':
        break;
      case 'on':
      case 'off':
      case 'read':
      case 'gone':
      case 'unsub':
        this.handleResetChatList();
        break;
      case 'msg':
        // Check if the topic is archived. If so, don't play a sound.
        if (cont.unread > 0 && !archived) {
          // TODO: play sound!
        }

        this.handleResetChatList();
        break;
      default:
        captureError(new Error(`Unhandled contact update: ${what}`));
        break;
    }
  };

  getTopicInfo = (topicName: string) => {
    const topicInfo: TopicInfo = {
      topicName: '?',
    };

    if (topicName && topicName !== '' && topicName !== '?') {
      topicInfo.topicName = topicName;
      if (topicName.indexOf('#') >= 0) {
        const nameArr = topicName.split('#');
        const pCustTag = nameArr.find((element) => element.includes('pcust:'));
        if (pCustTag) {
          const custArr = pCustTag.split(',');
          const rCNames: string[] = [];
          let custNames = '';

          if (custArr.length > 0) {
            if (custArr[0] !== '') {
              custNames = custArr[0].substr(6);
            }
            if (custArr.length > 1) {
              custArr.forEach((v, i) => {
                if (i !== 0) {
                  rCNames.push(v.substr(6));
                  rCNames.sort();
                }
              });
            }
            if (rCNames.length > 0) {
              if (custNames !== '') {
                custNames = custNames + ', ' + rCNames.join(', ');
              } else {
                custNames = rCNames.join(', ');
              }
            }
            topicInfo.custNames = custNames;
          }
        }
        const asgTag = nameArr.find((element) => element.includes('asg:'));
        if (asgTag) {
          const asgName = asgTag.split(':').pop();
          if (asgName && asgName !== '') {
            topicInfo.assignee = asgName;
          } else {
            topicInfo.assignee = 'Unassigned';
          }
        }
        const oppTag = nameArr.find((element) => element.includes('opp:'));
        if (oppTag) {
          const oppID = oppTag.split(':').pop();
          if (oppID && oppID !== '') {
            topicInfo.oppID = oppID;
          }
        }
      } else {
        topicInfo.custNames = topicInfo.topicName.replace('gen:', '');
        topicInfo.assignee = 'General enquiry';
      }
    }

    return topicInfo;
  };

  handleResetChatList = async () => {
    const { data } = this.state;
    const list: ChatSession[] = [];

    this.tinode.getMeTopic().contacts(async (c: any) => {
      list.push({
        name: c.public ? c.public.fn : '?',
        topic: c.topic,
        touched: c.touched,
        updated: c.updated,
        online: c.online,
        unread: isNaN(c.unread) ? c.seq : c.unread,
        topicInfo: this.getTopicInfo(c.public ? c.public.fn : '?'),
      });
      if (list.length > 0) {
        // handle exception where for some reason touched is invalid
        list.sort((a, b) =>
          !a.touched || !a.touched.getTime() || !b.touched || !b.touched.getTime()
            ? 0
            : b.touched.getTime() - a.touched.getTime()
        );
      }
    });
    await this.setStateAsync({ data: { ...data, list, ready: true } });
  };

  async authenticateChat(username: string, jwt: string) {
    let ctrl = null;
    try {
      ctrl = await this.tinode.login('mhub', b64EncodeUnicode(`${username}:${jwt}`));
    } catch (err) {
      if (err.message !== 'user not found (404)') {
        captureError(err);
        return null;
      }
    }

    await this.handleAuthSuccessful();

    return ctrl;
  }

  async register(username: string, jwt: string, login: boolean = false) {
    await this.tinode.createAccount('mhub', b64EncodeUnicode(`${username}:${jwt}`), login);
  }

  render() {
    const { children } = this.props;
    const { data } = this.state;
    const value: IChatContext = {
      actions: {
        getTinode: this.getTinode,
        getTopic: this.getTopic,
        search: this.search,
      },
      state: data,
    };
    return <ChatContextProvider value={value}>{children}</ChatContextProvider>;
  }
}

ChatProvider.contextType = AuthenticationContext;
