import React from 'react';
import { AuthenticationContext, IAuthenticationContext } from '../Authentication';
import { captureError } from '../../utils/errors';
import { getAdmins, addAdmin, editAdmin, deleteAdmin, getShowroomBranchProjects } from '../../utils/api';
import { Branch, ChatAdmin } from '../../screens/Setting/Settings.types';
import Bugsnag from '@bugsnag/js';

interface User {
  id: string;
  admin_name: string;
  company_id: string;
  company_branch_id: string;
  company_branch_name: string;
  email: string;
  groups: string[];
  phone: string;
  admin_role: string;
  updated_at?: string;
}

interface AdminState {
  readonly ready?: boolean;
  readonly chatAdmins?: ChatAdmin[];
}

interface AdminActions {
  createAdmin: (value: User, adminType: string) => Promise<ChatAdmin | null>;
  updateAdmin: (id: string, value: ChatAdmin, adminType: string) => Promise<ChatAdmin | null>;
  deleteAdmin: (id: string) => Promise<ChatAdmin | null>;
}

export interface IAdminContext {
  state: AdminState;
  actions: AdminActions;
}

const initialState: AdminState = {
  ready: false,
  chatAdmins: [],
};

export const AdminContext = React.createContext<IAdminContext>({
  state: initialState,
  actions: {
    createAdmin: async () => null,
    updateAdmin: async () => null,
    deleteAdmin: async () => null,
  },
});

export const AdminContextProvider = AdminContext.Provider;
export const AdminContextConsumer = AdminContext.Consumer;

interface IAdminProviderProps {
  filter?: string;
  children: React.ReactNode;
}

interface IAdminProviderState {
  readonly data: AdminState;
}

export default class AdminProvider extends React.Component<IAdminProviderProps, IAdminProviderState> {
  constructor(props: IAdminProviderProps) {
    super(props);

    this.state = {
      data: initialState,
    };
  }

  async componentDidMount() {
    this.fetchAdmins();
  }

  setStateAsync = (state: IAdminProviderState): Promise<void> =>
    new Promise((res) => {
      this.setState(state, res);
    });

  fetchAdmins = async () => {
    const { data } = this.state;
    const chatAdmins: ChatAdmin[] = [];
    const { state, actions }: IAuthenticationContext = this.context;
    if (state.authenticated) {
      const authUser = actions.getAuthUser();
      if (authUser && authUser.companyID) {
        const adms: ChatAdmin[] = await getAdmins(authUser.companyID);
        adms.forEach((a) => {
          chatAdmins.push(a);
        });
        chatAdmins.sort((a, b) => a.admin_name.localeCompare(b.admin_name));
      }
      await this.setStateAsync({
        data: {
          ...data,
          ready: true,
          chatAdmins,
        },
      });
    }
  };

  fetchCompanyBranches = async (showroomAPIURL: string, userInfo: string, companyID: string) => {
    const fetchBranchesByPage = async (page = 1) => {
      let apiEndpoint = '';
      if (page === 1) {
        apiEndpoint = `${showroomAPIURL}/company_branches/`;
      } else {
        apiEndpoint = `${showroomAPIURL}/company_branches/?page=${page}`;
      }
      const res = await fetch(apiEndpoint, {
        credentials: 'include',
        mode: 'cors',
      });
      return res;
    };

    const arr: Array<Branch> = [];

    try {
      let page = 1;
      let cont = true;
      while (cont) {
        const res = await fetchBranchesByPage(page);
        const d = await res.json();

        if (d !== null) {
          d.forEach((b: Branch) => {
            const obj: Branch = {
              id: b.id,
              name: b.name,
              service_company_branch_id: b.service_company_branch_id,
              viewAllProjects: b.viewAllProjects,
            };
            arr.push(obj);
          });
          page++;
        } else {
          cont = false;
        }
      }
      return arr;
    } catch (err) {
      console.log('Error in fetchCompanyBranches: ', err);
    }
  };

  getBranchProjects = async (val: ChatAdmin) => {
    const { actions }: IAuthenticationContext = this.context;
    const authUser = actions.getAuthUser();
    const initProjectIDs: string[] = [];
    let viewAllProjects = false;

    if (authUser && authUser.showroomAPIURL && authUser.showroomAPIURL !== '') {
      const userInfo = `${authUser.name} (${authUser.username})`;
      const showroomAPIRUL = authUser.showroomAPIURL;
      const branches = await this.fetchCompanyBranches(showroomAPIRUL, userInfo, authUser.companyID);
      if (branches && branches.length > 0) {
        const branchInfo = branches.find((obj) => obj.service_company_branch_id === val.company_branch_id);
        if (branchInfo && branchInfo.viewAllProjects) {
          viewAllProjects = branchInfo.viewAllProjects;
        }
        if (viewAllProjects === false) {
          // lookup project list
          if (branchInfo && branchInfo.id && branchInfo.id !== '') {
            const branchProjects = await getShowroomBranchProjects(
              authUser?.username || '?',
              authUser.companyID,
              branchInfo?.id
            );
            if (branchProjects && branchProjects?.length > 0) {
              branchProjects.forEach((bp: string) => {
                if (authUser && authUser.projects && authUser.projects.length > 0) {
                  const projID = authUser.projects.find((obj) => obj.loanTrackerProjectID === bp);
                  if (projID && projID.id && projID.id !== '') {
                    initProjectIDs.push(projID.id);
                  }
                }
              });
            }
          }
        }
      }
    }
    return { viewAllProjects: viewAllProjects, branchProjects: initProjectIDs };
  };

  createAdmin = async (val: User, adminType: string) => {
    const { actions }: IAuthenticationContext = this.context;
    const authUser = actions.getAuthUser();
    const initProjectIDs: string[] = [];
    let viewAllProjects = false;

    let author = '';
    if (authUser && authUser.name) {
      author = authUser.name;
    }

    if (adminType === 'global') {
      viewAllProjects = true;
    } else {
      const branchInfo = await this.getBranchProjects(val);
      if (branchInfo) {
        viewAllProjects = branchInfo.viewAllProjects;
        if (branchInfo.branchProjects.length > 0) {
          branchInfo.branchProjects.map((bp: string) => initProjectIDs.push(bp));
        }
      }
    }
    const input: ChatAdmin = {
      id: '',
      user_id: val.id,
      admin_name: val.admin_name,
      company_id: val.company_id,
      company_branch_id: val.company_branch_id,
      company_branch_name: val.company_branch_name,
      email: val.email,
      groups: [],
      phone: val.phone,
      admin_role: val.admin_role,
      branch_projects: initProjectIDs,
      view_all_projects: viewAllProjects,
      created_by: author,
    };
    await addAdmin(input, author);
    this.fetchAdmins();
    return null;
  };

  updateAdmin = async (id: string, val: ChatAdmin, adminType: string) => {
    const { actions }: IAuthenticationContext = this.context;
    const authUser = actions.getAuthUser();
    const initProjectIDs: string[] = [''];
    let viewAllProjects = false;

    let author = '';
    if (authUser && authUser.name) {
      author = authUser.name;
    }

    if (adminType === 'global') {
      viewAllProjects = true;
    } else {
      // branch
      const branchInfo = await this.getBranchProjects(val);
      if (branchInfo) {
        viewAllProjects = branchInfo.viewAllProjects;
        if (branchInfo.branchProjects.length > 0) {
          branchInfo.branchProjects.map((bp: string) => initProjectIDs.push(bp));
        }
      }
    }
    const input: ChatAdmin = {
      id: id,
      user_id: val.id,
      admin_name: val.admin_name,
      company_id: val.company_id,
      company_branch_id: val.company_branch_id,
      company_branch_name: val.company_branch_name,
      email: val.email,
      groups: [],
      phone: val.phone,
      admin_role: val.admin_role,
      branch_projects: initProjectIDs,
      view_all_projects: viewAllProjects,
      updated_by: author,
    };

    await editAdmin(input);
    this.fetchAdmins();
    return null;
  };

  deleteAdmin = async (id: string) => {
    await deleteAdmin(id);
    this.fetchAdmins();
    return null;
  };

  handleError = (err: Error) => {
    captureError(err);
  };

  render() {
    const { children } = this.props;
    const { data } = this.state;
    const value: IAdminContext = {
      state: data,
      actions: {
        createAdmin: this.createAdmin,
        updateAdmin: this.updateAdmin,
        deleteAdmin: this.deleteAdmin,
      },
    };
    return <AdminContextProvider value={value}>{children}</AdminContextProvider>;
  }
}

AdminProvider.contextType = AuthenticationContext;
