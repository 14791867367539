import React, { useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AuthenticationContext } from './contexts/Authentication';
import ChatProvider from './contexts/Chat';
import Routes from './routes';

function App() {
  const authContext = useContext(AuthenticationContext);
  const { state: authState } = authContext;

  if (!authState.loading && !authState.authenticated) {
    return <div>Login required </div>;
  }

  return (
    <div>
      <CssBaseline />
      <ChatProvider filter="my">
        <Routes />
      </ChatProvider>
    </div>
  );
}

export default App;
