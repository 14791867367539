/* eslint-disable no-underscore-dangle */

export interface AWSResponse {
  data: any;
  status: number;
  statusText: string;
  headers: { [key: string]: string };
}

export function isAWSResponse(obj: any): obj is AWSResponse {
  if (!obj
    || !obj.status
    || !obj.headers
    || !obj.data) {
    return false;
  }
  return typeof obj.status === 'number'
    && typeof obj.headers === 'object'
    && typeof obj.data === 'object';
}

export interface AWSError {
  code?: string;
  name?: string;
  response?: AWSResponse;
  message: string;
}

export function isAWSError(obj: any): obj is AWSError {
  if (obj.response) {
    return typeof obj.message === 'string' && isAWSResponse(obj.response);
  }
  return typeof obj.message === 'string'
    && typeof obj.code === 'string'
    && typeof obj.name === 'string';
}
