import {
    DEVELOPER_EXECUTIVE,
    DEVELOPER_HEADQUARTERS,
    DEVELOPER_BRANCH_LEADER_SUPERVISOR,
    DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
    DEVELOPER_BRANCH_LEADER,
  } from './groups';
  
  export const ROLE_HEADQUARTERS = 'Headquarters';
  export const ROLE_SUPERVISOR = 'Supervisor';
  export const ROLE_SALES_MANAGER = 'Sales Manager';
  export const ROLE_SALES_ADMIN = 'Sales Admin';
  export const ROLE_STAFF = 'Staff';
  
  // From backend, 1 = Headquarters ...
  export const ROLE_MAP = [
    undefined,
    ROLE_HEADQUARTERS,
    ROLE_SUPERVISOR,
    ROLE_SALES_MANAGER,
    ROLE_SALES_ADMIN,
    ROLE_STAFF,
  ];
  
  export const ROLE_HEADQUARTERS_GROUPS = [
    DEVELOPER_EXECUTIVE,
    DEVELOPER_HEADQUARTERS,
  ];
  
  export const ROLE_SUPERVISOR_GROUPS = [
    DEVELOPER_EXECUTIVE,
    DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  ];
  
  export const ROLE_SALES_MANAGER_GROUPS = [
    DEVELOPER_EXECUTIVE,
    DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  ];
  
  export const ROLE_SALES_ADMIN_GROUPS = [
    DEVELOPER_EXECUTIVE,
    DEVELOPER_BRANCH_LEADER,
  ];
  
  export const STAFF_GROUPS = [DEVELOPER_EXECUTIVE];
  
  export function isHeadquartersRole(groups = []) {
    return Array.isArray(groups) && groups.length > 0 && ROLE_HEADQUARTERS_GROUPS.every(hg => groups.includes(hg));
  }
  
  export function isSupervisorRole(groups = []) {
    return Array.isArray(groups) && groups.length > 0 && ROLE_SUPERVISOR_GROUPS.every(sg => groups.includes(sg));
  }
  
  export function isSalesManagerRole(groups = []) {
    return Array.isArray(groups) && groups.length > 0 && ROLE_SALES_MANAGER_GROUPS.every(smg => groups.includes(smg));
  }
  
  export function isSalesAdminRole(groups = []) {
    return Array.isArray(groups) && groups.length > 0 && ROLE_SALES_ADMIN_GROUPS.every(sag => groups.includes(sag));
  }
  
  export function isStaffRole(groups = []) {
    return Array.isArray(groups) && groups.length > 0 && STAFF_GROUPS.every(sg => groups.includes(sg));
  }
  
  export function getUserRole(groups) {
    if (groups) {
      switch (true) {
        case isHeadquartersRole(groups):
          return ROLE_HEADQUARTERS;
        case isSupervisorRole(groups):
          return ROLE_SUPERVISOR;
        case isSalesManagerRole(groups):
          return ROLE_SALES_MANAGER;
        case isSalesAdminRole(groups):
          return ROLE_SALES_ADMIN;
        default:
          return ROLE_STAFF;
      }
    }
    return ROLE_STAFF;
  }