import { createMuiTheme } from '@material-ui/core/styles';
import * as color from './color';
import * as font from './font';
const defaultTheme = createMuiTheme();

export const customTheme = createMuiTheme({
  shadows: Array(25).fill('none'),
  palette: {
    primary: color.primary,
    secondary: color.secondary,
    tertiary: color.tertiary,
    font: color.font,
    text: {
      primary: color.font.header,
    },
    error: {
      main: color.tertiary.red,
      dark: defaultTheme.palette.error.dark,
      light: color.tertiary.alertRed,
    },
  },
  typography: {
    fontFamily: font.bodyFont,
    color: color.font.body,
  },
  overrides: {
    MuiTypography: {
      h1: font.h1,
      h2: font.h2,
      h3: font.h3,
      h4: font.h4,
      h5: font.h5,
      h6: font.h6,
      subtitle1: font.subtitle1,
      subtitle2: font.subtitle2,
      body1: font.body1,
      body2: font.body2,
      caption: font.caption,
    },
    MuiAutocomplete: {
      endAdornment: {
        top: 'unset',
      },
      inputRoot: {
        padding: '0 4.43rem 0 0',
      },
    },

    MuiButton: {
      root: {
        ...font.buttonFont,
        minWidth: '70px',
        minHeight: '35px',
        padding: '0 1rem',
        '&:disabled': {
          color: color.tertiary.grey,
          '& img': {
            filter: 'invert(50%)',
            opacity: '0.5',
          },
        },
      },
      label: {
        textTransform: 'none',
      },
      contained: {
        backgroundColor: color.secondary.main,
        color: defaultTheme.palette.common.white,
        '&:hover': {
          backgroundColor: color.secondary.light,
        },
        '&:disabled': {
          color: color.tertiary.grey,
          backgroundColor: color.tertiary.mediumGrey,
          '& img': {
            filter: 'invert(50%)',
            opacity: '0.5',
          },
        },
        '&:not(:disabled) img': {
          filter: 'invert(1)',
        },
      },
      text: {
        padding: '0.36rem 1.43rem',
        '&:hover': {
          backgroundColor: 'unset',
          color: color.font.body,
        },
        '&:active': {
          color: color.font.subBody,
        },
        '&:disabled': {
          color: color.tertiary.grey,
        },
      },
      outlined: {
        color: color.font.body,
        borderColor: color.tertiary.grey,
        padding: '0 1rem',
        '&:hover': {
          borderColor: color.tertiary.mediumGrey,
          backgroundColor: color.tertiary.mediumGrey,
        },
        '&:disabled': {
          border: `1px solid ${color.tertiary.lightGrey}`,
          backgroundColor: color.tertiary.mediumGrey,
          '& img': {
            opacity: '0.2',
          },
        },
        '&:not(:disabled) img': {
          filter: 'invert(42%) sepia(23%) saturate(316%) hue-rotate(163deg) brightness(89%) contrast(92%)',
        },
      },
      sizeSmall: {
        ...font.smallButtonFont,
        minWidth: 'unset',
        minHeight: '30px',
        padding: '0 1rem',
        '& .MuiButton-label .MuiButton-startIcon img': {
          width: '20px',
          height: '18px',
        },
      },
      startIcon: {
        marginRight: '4px',
      },
    },

    MuiInputBase: {
      root: {
        borderRadius: '4px',
        '&$disabled': {
          color: color.font.subBody,
          backgroundColor: color.tertiary.mediumGrey,
        },
        '&$disabled > svg': {
          color: color.tertiary.grey,
        },
        'label[data-shrink=false] + &$formControl input::-webkit-input-placeholder': {
          color: color.font.subBody,
          opacity: '1 !important',
        },
      },
      input: {
        '&:-webkit-autofill': {
          boxShadow: '0 0 0 30px white inset',
        },
        '&::placeholder': {
          color: color.font.subBody,
          opacity: '1 !important',
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: `1px solid ${color.primary.main}`,
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          border: `1px solid ${color.primary.main}`,
        },
        '&$disabled $notchedOutline': {
          borderColor: color.tertiary.mediumGrey,
        },
      },
      input: {
        border: '1px solid transparent',
        boxSizing: 'border-box',
        height: '40px',
        padding: '0.7rem 1rem',
      },
      notchedOutline: {
        borderColor: color.tertiary.grey,
      },
    },

    MuiIconButton: {
      root: {
        color: '#49B3B1',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiInputLabel: {
      formControl: {
        position: 'unset',
        transform: 'unset',
        marginBottom: '0.5rem',
        fontSize: '0.875rem',
        fontWeight: font.semiBold,
        color: '#00233b',
      },
      root: {
        fontSize: '0.875rem',
        fontWeight: font.semiBold,
        color: '#00233b',
        marginBottom: '0.5rem',
        '&$focused': {
          color: '#00233b',
        },
        '& .MuiInputLabel-asterisk': {
          color: color.tertiary.alertRed,
        },
      },
    },

    MuiFormLabel: {
      root: {
        color: '#000000',
      },
    },

    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: color.tertiary.grey,
        },
      },
    },

    MuiFormHelperText: {
      contained: {
        marginTop: '3px',
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MuiChip: {
      root: {
        alignItems: 'center',
        fontSize: '0.71rem',
        height: '1.43rem',
      },
      label: {
        padding: '0.21rem 0.71rem',
        color: '#596975',
      },
      outlined: {
        border: `1px solid #DBDDDE`,
      },
    },

    MuiList: {
      root: {
        padding: '0',
      },
    },

    MuiPaper: {
      root: {
        border: `1px solid ${color.tertiary.grey}`, 
      },
    },

    MuiSwitch: {
      root: {
        width: 30,
        height: 15,
        padding: 0,
        margin: defaultTheme.spacing(1),
      },
      colorSecondary: {
        '&$thumb': { backgroundColor: color.tertiary.grey },
        '&$checked': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: color.tertiary.green,
          },
          '&$disabled': {
            color: defaultTheme.palette.common.white,
            '& + $track': {
              backgroundColor: color.tertiary.mediumGrey,
            },
          },
        },
        '&$disabled': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: color.tertiary.mediumGrey,
          },
        },
      },
      switchBase: {
        padding: 0,
        color: defaultTheme.palette.common.white,
        border: `1px solid ${color.tertiary.mediumGrey}`,
        '&$checked': {
          transform: 'translateX(15px)',
          border: `1px solid ${color.primary.main}`,
          '&$disabled': {
            border: `1px solid ${color.tertiary.mediumGrey}`,
          },
          '& + $track': {
            opacity: 1,
          },
        },
        '&$disabled + $track': {
          opacity: 1,
        },
      },
      track: {
        borderRadius: 26 / 2,
        opacity: 1,
        transition: defaultTheme.transitions.create(['background-color', 'border', 'transform']),
        backgroundColor: color.tertiary.grey,
      },
      thumb: {
        width: 13,
        height: 13,
      },
    },

  },
});
